import React from "react";
import { Link } from "react-router-dom";
export default function(props) {
  return (
    <footer className="bg-secondary text-secondary-light container-fluid h6 font-weight-light m-0 py-2 border-top border-secondary-light">
      <div className="d-flex">
        <span>&copy; Copyright</span>
        <Link to="/" className="ml-auto mr-3 text-secondary-light">
          Home
        </Link>
        <Link to="/privacy-policy" className="text-secondary-light">
          Privacy Policy
        </Link>
      </div>
    </footer>
  );
}
