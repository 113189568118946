import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./scss/style.scss";
import RecentPost from "./components/recentPost";
import axios from "axios";
import SearchSection from "./components/searchSection";
// import { CategoryConsumer } from "./menu";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import logo from "./image/subhakamana_logo.png";
import { SiteConsumer } from "./sitecontext";
// import { Helmet } from 'react-helmet';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      value: "",
      slideCount: 9,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.history.push("search/" + this.state.value);
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize);
    axios.get("/menu/getUpcomingCategories").then((response) => {
      this.setState({ events: response.data });
    });
    // axios.get("/settings").then(response => {
    // 	this.setState({
    // 		site_setting: response.data.data
    // 	})
    // })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize() {
    let slideCount = window.innerWidth / 170;
    this.setState({
      slideCount,
    });
  }

  render() {
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: this.state.slideCount,
      slidesToScroll: 1,
    };
    return (
      <>
        <div
          className="bg-image text-center overflow-hidden position-relative"
          style={{ minHeight: "70vh" }}
        >
          <div className="pb-5 my-5">
            <SiteConsumer>
              {(site_setting) => (
                <>
                  <h2 className="text-white mt-4 px-2">
                    {site_setting.site_tag_line}
                  </h2>
                  <div className="text-white">
                    {site_setting.site_description}
                  </div>
                </>
              )}
            </SiteConsumer>
            <SearchSection history={this.props.history} />
          </div>
          <div
            className="position-absolute p-2 rounded shadow bg-primary"
            style={{
              bottom: "100px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.subhakamana&hl=en"
              className="bg-primary"
              target="_blank"
            >
              <img
                src="/static/media/Google-Play-Icon.png"
                className="width-150"
                alt="Download on Google Play Store"
              />
            </a>
          </div>
        </div>
        {/* <CategoryConsumer> */}
        {this.state.events.length !== 0 && (
          <div className="mt-m-80 shadow">
            <Slider {...settings}>
              {this.state.events.map(function (menu) {
                return (
                  <Link
                    to={"/search/" + menu.slug}
                    className="px-2 text-secondary"
                    key={menu.slug}
                  >
                    <div className="bg-white rounded overflow-hidden text-center slide-card">
                      <div className="pt-100 position-relative bg-light z-index">
                        <img
                          src={menu.image ? `uploads/${menu.image}` : logo}
                          style={{ opacity: menu.image ? 1 : 0.2 }}
                          className="position-absolute w-100 h-100 fixed-top p-2"
                          loading="lazy"
                          alt={menu.name}
                        />
                      </div>
                      <div className="p-2 slide-card-overlay z-index-2 bg-white">
                        {menu.name}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        )}
        {/* </CategoryConsumer> */}
        <RecentPost />
      </>
    );
  }
}

export default App;
