import React, { Component } from "react";

export default class searchSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: "",
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleSearch(e) {
    e.preventDefault();
    if (this.state) {
      this.props.history.push("/search/" + this.state.submit);
    }
  }
  handleChange(e) {
    this.setState({
      submit: e.target.value,
    });
  }
  render() {
    return (
      <section className="section-block">
        <div className="container max-width-700 pt-4">
          <form
            className="d-flex h2 p-2 bg-primary w-100 shadow br-50"
            onSubmit={this.handleSearch}
          >
            <input
              type="text"
              className="form-control search rounded-pill px-4"
              value={this.state.submit}
              onChange={this.handleChange}
              placeholder="Search 1000+ templates"
              aria-label="search"
              aria-describedby="search"
            />
            <button
              className="btn btn-secondary search-button ml-2 py-1 rounded-pill"
              type="Submit"
            >
              <i className="fas fa-search"></i>&nbsp;&nbsp;Search
            </button>
          </form>
        </div>
      </section>
    );
  }
}
