import React from "react";
import Header from "../header";
import SubscribeBlock from "../subscribe";
import Footer from "../footer";

export default function({ noFooter, children }) {
  return (
    <>
      <Header />
      {children}
      <SubscribeBlock />
      <Footer />
    </>
  );
}
