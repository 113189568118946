import React, { Component } from "react";

export default class privacyPolicy extends Component {
  render() {
    return (
      <main>
        <div className="bg-white container border my-4 py-3 px-5">
          <h1 className="h2 text-center">Privacy Policy</h1>
          <div className="mb-4">
            <time datetime="2020-09-09" class="small">
              Updated at 2020-09-09
            </time>
          </div>
          <p className="mb-3">
            The Privacy Policy explains how your personal information is
            collected, used and disclosed by Subhakamana.
          </p>
          <p className="mb-3">
            The Privacy Policy applies to our website,
            <a href="https://subhakamana.com">https://subhakamana.com</a>. and
            its associated subdomains (collectively, our “Service”) along with
            our Mobile Application Subhakamana. By accessing or using our
            Service, you signify that you have read, understood, and agree to
            our collection, storage, use and disclosure of your personal
            information as described in this Privacy Policy and our Terms of
            Service.
          </p>
          <h2 className="h3 mt-4">Meanings</h2>
          <p>For this Privacy Policy</p>
          <ol className="mt-3">
            <li>
              <b>You</b> is referred to the person who accessed the Service, or
              the company, or other legal entity on behalf of which such
              individual is accessing or using the Service, as applicable
            </li>
            <li>
              <b>Company</b> (referred to as either “the Company”, “We”, “Us” or
              “Our” in this Agreement)
            </li>
            <li>
              <b>Website</b> is referred to your website, accessible from
              <a href="https://subhakamana.com">https://subhakamana.com</a>
            </li>
            <li>
              <b>Service</b> is referred to the Website and Mobile Application
            </li>
            <li>
              <b>Service</b> Provider means any natural or legal person who
              processes the data on behalf of the Company. It referes to
              third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company.
            </li>
            <li>
              <b>Third-party</b> Social Media Service could be any website or
              social network website in which a User can log in or create an
              account to use the Service.
            </li>
            <li>
              <b>Personal</b> Data is any information that relates to an
              identified and identifiable individual.
            </li>
            <li>
              <b>Cookies</b> are a small amount of data generated by a website
              or app and saved by your web browser. Its purpose is to remember
              information about you, similar to a preference file created by a
              software application.
            </li>
            <li>
              <b>Device</b> could be any device with internet who can access the
              service such as a mobile phone, a computer or a digital tablet.
            </li>
            <li>
              <b>Usage</b> Data it’s referred to the data that’s been collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself. For Example, how many times the
              user accessed the website or mobile application.
            </li>
          </ol>
          <h2 className="h3 mt-4">What Information Do We Collect?</h2>
          <p>
            We collect information from you when you visit our service,
            register,subscribe to our newsletter, respond to a survey or fill
            out a form.
          </p>
          <ol className="mt-3">
            <li>Email Address</li>
            <li>Name/Username</li>
            <li>Social Media profile information</li>
            <li>
              We also collect information from mobile devices for a better user
              experience, although these features are completely optional
            </li>
            <li>
              Camera (Pictures): Granting camera permission allows the user to
              upload any picture straight from the website and mobile
              application, you can safely deny camera permissions
            </li>
          </ol>
          <h2 className="h3 mt-4">How Do We Use the Information We Collect?</h2>
          <p>
            Any of the information we collect from you may be used in one of the
            following ways:
          </p>
          <ol>
            <li>
              To personalize your experience (your information helps us to
              better respond to your individual needs)
            </li>
            <li>
              To improve our service (we continuously strive to improve our
              service offerings based on the information and feedback we receive
              from you)
            </li>
            <li>
              To improve customer service (your information helps us to more
              effectively respond to your customer service requests and support
              needs)
            </li>
            <li>To process transactions</li>
            <li>
              To administer a contest, promotion, survey or other site feature
            </li>
            <li>To send periodic emails</li>
          </ol>
          <h2 className="h3 mt-4">
            When do we use customer information from third parties?
          </h2>
          <p>
            We receive some information from the third parties when you contact
            us. For example, when you submit your email address to us to show
            interest in becoming our customer, we receive information from a
            third party that provides automated fraud detection services to us.
            We also occasionally collect information that is made publicly
            available on social media websites. You can control how much of your
            information social media websites make public by visiting these
            websites and changing your privacy settings.
          </p>
          <h2 className="h3 mt-4">
            Do we share the information we collect with third parties?
          </h2>
          <p className="mb-3">
            We may share the information that we collect, both personal and
            non-personal, with third parties such as advertisers, contest
            sponsors, promotional and marketing partners, and others who provide
            our content or whose products or services we think may interest you.
            We may also share it with our current and future affiliated
            companies and business partners and if we are involved in a merger,
            asset sale or other business reorganization, we may also share or
            transfer your personal and non-personal information to our
            successors-in-interest.
          </p>
          <p className="mb-3">
            We may engage trusted third party service providers to perform
            functions and provide services to us, such as hosting and
            maintaining our servers and our service, database storage and
            management, e-mail management, storage marketing, credit card
            processing, customer service. We will likely share your personal
            information, and possibly some non-personal information, with these
            third parties to enable them to perform these services for us and
            for you.
          </p>
          <p className="mb-3">
            We may share portions of our log file data, including IP addresses,
            for analytics purposes with third parties such as web analytics
            partners, application developers, and ad networks. If your IP
            address is shared, it may be used to estimate general location and
            other technographics such as connection speed, whether you have
            visited the service in a shared location, and type of device used to
            visit the service. They may aggregate information about our
            advertising and what you see on the service and then provide
            auditing, research and reporting for us and our advertisers.
          </p>
          <p>
            We may also disclose personal and non-personal information about you
            to government or law enforcement officials or private parties as we,
            in our sole discretion, believe necessary or appropriate in order to
            respond the claims, legal process (including subpoenas), to protect
            our rights and interests or those of a third party, the safety of
            the public or any person, to prevent or stop any illegal, unethical,
            or legally actionable activity, or to otherwise comply with
            applicable court orders, laws, rules and regulations.
          </p>
          <h2 className="h3 mt-4">
            Where and when is information collected from customers and end users
            ?
          </h2>
          <p>
            We will collect personal information that you submit to us. We may
            also receive personal information about you from third parties as
            described above.
          </p>
          <h2 className="h3 mt-4">How Do We use Your Email Address ?</h2>
          <p>
            By submitting your email address on our website or mobile
            application, you agree to receive emails from us. You can cancel
            your participation in any of these email lists at any time by
            clicking on the opt-out link or othere unsubscribe option that is
            included in the respective email. We only send emails to people who
            have authorized us to contact them, either directly, or through a
            third party. We do not send unsolicited commercial emails, because
            we hate spam as much as you do. By submitting your email address,
            you also agree to allow us to use your email address for customer
            audience targeting on sites like Facebook, where we display custom
            advertising to specific people who have opted-in to receive
            communications from us. Note: if any time you would like to
            unsubscribe from receiving future emails, we include detailed
            unsubscribe instructions at the bottom of each email.
          </p>
          <h2 className="h3 mt-4">
            Is the information collected through our service secure?
          </h2>
          <p>
            We take precautions to protect the security of your information. We
            have physical, electronic and managerial procedures to help
            safeguard, prevent unauthorized access, maintain data security, and
            correctly use your information. However, neither people nor security
            systems are foolproof, including encryption systems. In addition,
            people can commit intentional crimes, make mistakes or fail to
            follow policies. Therefore, while we use reasonable efforts to
            protect your personal information, we cannot guarantee its absolute
            security. If applicable law imposes any non-disclaimable duty to
            protect your personal information, you agree that international
            misconduct will be the standards used to measure our compliance with
            that duty.
          </p>
          <h2 className="h3 mt-4">Can I update or correct my information?</h2>
          <p className="mb-3">
            The rights you have to request updates or corrections to the
            information we collect depend on your relationship with us.
            Personnel may update or correct their information as detailed in our
            internal company employment policies. Customers have the right to
            request the restriction of certain uses and disclosures of
            personally identifiable information as follows:
          </p>
          <p className="mb-1">You can contact us in order to</p>
          <ol>
            <li>Update or correct your personally identifiable information</li>
            <li>
              Change your preference with respect to communications and other
              information you receive from us
            </li>
            <li>
              Delete the personally identifiable information maintainted about
              you on our systems
            </li>
          </ol>
          <p>
            To protect your privacy and security, we may take reasonable steps
            to verify your identity before granting your profile access or
            making corrections. You are responsible for maintaining the secrecy
            of your unique password and account information all the times. You
            should be aware that it is not technologically possible to remove
            each and every record of the information you have provided to us
            from our system. If you are an end user and wish to update, delete
            or receive any information we have about you, you may do so by
            contacting the organization of which you are a customer.
          </p>
          <h2 className="h3 mt-4">Sale of Business</h2>
          <p>
            We reserve the right to transfer information to a third party in the
            event of a sale, merger or other transfer of all or substantially
            all of assets of us or any of its Corporate Affiliates, or that
            portion of us or any of its Corporate Affiliates to which the
            Service relates, or in the event that we discontinue our business or
            file a petition or have filed against us a petition in bankruptcy,
            reorganization or similar proceeding, provided that the third party
            agrees to adhere to the terms of this Privacy Policy.
          </p>
          <h2 className="h3 mt-4">How Long Do We Keep Your Information?</h2>
          <p>
            We keep your information only so long as we need it to provide
            service to you and fulfill the purposes described in this policy.
            This is also the case for anyone that we share your information with
            and who carries out services on our behalf. We no longer need to use
            your information and there is no need for us to keep it to comply
            with our legal or regulatory obligations, we’ll either remove it
            from our systems or depersonalize it so that we can’t identify you
          </p>
          <h2 className="h3 mt-4">How Do We Protect Your Information?</h2>
          <p>
            We implement a variety of security measures to maintain the safety
            of your personal information when you place an order or enter,
            submit or access your personal information. We offer the use of a
            secure server. All supplied sensitive/credit information is
            transmitted via Secure Socket Layer (SSL) technology and then
            encrypted the information shared only to be shared by those
            authorized with special rights to such systems, and are required to
            keep the information confidential. We cannot, however, ensure or
            warrant the absolute security of any information you transmit to us
            or guarantee that your information on the Service may not be
            accessed, disclosed , altered, or destroyed of any of our physical,
            technical, or managerial safeguards
          </p>
          <h2 className="h3 mt-4">Governing Law</h2>
          <p>
            The laws of, excluding its conflicts of law rules. Shall govern this
            Agreement and your use of our service. Your use of our service may
            also be subject to other local, state, national or international
            laws.
          </p>
          <h2 className="h3 mt-4">Your Consent</h2>
          <p>
            By using our service, registering an account, making a purchase, you
            consent to the Privacy Policy.
          </p>
          <h2 className="h3 mt-4">Links to Other Websites</h2>
          <p className="mb-3">
            Our service may contain links to other websites that are not
            operated by us. If you click on a third party link. You will be
            directed to that third party’s site. We strongly advise you to
            review the Privacy Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h2 className="h3 mt-4">Cookies</h2>
          <p>
            We use “Cookies” to identify the areas of our website that you have
            visisted. A Cookie is a small piece od data stored on your computer
            or mobile device by your web browser. We use Cookies to personalize
            the Content that you see on our website. Most web browsers can be
            set to disable the use of Cookies. However, if you disable Cookies,
            you may not be able to access functionality on our website correctly
            or at all. We never place personally identifiable information in
            Cookies.
          </p>
          <h2 className="h3 mt-4">Advertising</h2>
          <p>
            Advertising keeps us and many of the websites and services you use
            free of charge. We work hard to make sure that ads are safe,
            unobtrusive, and as relevant as possible. Cookies help to make
            advertising more effective. Without cookies, it’s really hard for an
            advertiser to reach its audience, or to know how many ads were shown
            and how many clicks they received so we will be using your cookies
            information for advertising the relevant ads only.
          </p>
          <h2 className="h3 mt-4">Kid’s Privacy</h2>
          <p>
            We do not address anyone under the age of 13. We do not knowingly
            collect personally identifiable information from anyone under the
            age of 13. If you are a parent or guardian and you are aware that
            your child has provided us with personal Data, please contact us. If
            we become aware that we have collected Personal Data from anyone
            under the age of 13 without verification of parental consent, We
            take steps to remove that information from our servers.
          </p>
          <h2 className="h3 mt-4">Changes To Our Privacy Policy</h2>
          <p>
            If we decide to change our privacy policy, we will post those
            changes on this page, and/or upgrade the Privacy Policy modification
            date below.
          </p>
          <h2 className="h3 mt-4">Third-Party Services</h2>
          <p className="mb-3">
            We may display, include or make available third-party content
            (including data, information, applications and other products
            services) or provide links to third-party websites or services
            (“Third-Party Services”).
          </p>
          <p className="mb-3">
            You acknowledge and agree that we shall not be responsible for any
            Third-Party Services, including the accuracy, completeness,
            timeliness, validity, copyright compliance, legality, decency,
            quality or any other aspect thereof. We do not assume and shall not
            have any liability or responsibility to you or any other person or
            entity for any Third-Party Services.
          </p>
          <p>
            Third-Party Services and links thereto are provided solely as a
            convenience to you and you access and use them entirely at your own
            risk and subject to such third parties terms and conditions.
          </p>
          <h2 className="h3 mt-4">Contact Us</h2>
          <p className="mb-1">
            Don’t hesitate to contact us if you have any questions
          </p>
          <ol>
            <li>
              <address>
                Via Email:
                <a href="mailto:info@upvedatech.com">info@upvedatech.com</a>
              </address>
            </li>
            <li>
              Via this Link:
              <a href="https://subhakamana.com">https://subhakamana.com</a>
            </li>
            <li>Via this Address: New Baneshwor, Kathmandu, Nepal</li>
          </ol>
        </div>
      </main>
    );
  }
}
