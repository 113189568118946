import React from "react";

export default function Pagination(props) {
  function handleChange(i) {
    if (i !== props.current_page) {
      props.onChange(i);
    }
  }
  let pages = [];
  let start_page = props.current_page - 2;
  start_page = start_page > 0 ? start_page : 1;
  let end_page = start_page + 5;
  end_page = Math.ceil(
    end_page < props.total_page ? end_page : props.total_page
  );
  for (let i = start_page; i <= end_page; i++) {
    let className =
      i === props.current_page ? "page-item active disable" : "page-item";
    pages.push(
      <li className={className} key={i}>
        <button
          className="page-link"
          href="#"
          onClick={e => handleChange(i)}
          type="button">
          {i}
        </button>
      </li>
    );
  }

  if (end_page < 2) {
    return "";
  }

  return (
    <nav className="w-100 mt-4" aria-label="Page navigation">
      <ul className="pagination justify-content-center shadow">
        {props.current_page > 1 && (
          <li className="page-item">
            <button
              className="page-link"
              href="#"
              onClick={e => {
                e.preventDefault();
                props.onChange(props.current_page - 1);
              }}
              tabIndex="-1"
              type="button">
              <i className="fa fa-angle-left"></i>
              <span className="sr-only">Previous</span>
            </button>
          </li>
        )}
        {pages}
        {props.current_page < props.total_page && (
          <li className="page-item">
            <button
              className="page-link"
              href="#"
              onClick={e => {
                e.preventDefault();
                props.onChange(props.current_page + 1);
              }}
              type="button">
              <i className="fa fa-angle-right"></i>
              <span className="sr-only">Next</span>
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
}
