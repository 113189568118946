import styled from 'styled-components';

export default styled.div`
    width:auto;
    position:relative;
    
    img{
        height:auto;
        width:100%;
        top:0;
        background-color:#fff;
    }
`;