import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Image from "./image";
import SearchSection from "./searchSection";
import { u_hostname_uploads } from "../variables";
import Pagination from "./pagination";
import { SiteConsumer } from '../sitecontext';
const imageUrl = u_hostname_uploads;

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: props.match.params.id,
      results: [],
      loading: false,
      msg: "",
      page_size: 30,
    };
    this.doSearch = this.doSearch.bind(this);
    this.placeTemplates = this.placeTemplates.bind(this);
  }

  doSearch(e = 1) {
    this.setState({
      loading: true,
    });
    let keyword = this.state.search ? this.state.search : "";
    axios
      .post("/template/search", {
        search: keyword,
        page: e,
        pageSize: this.state.page_size,
      })
      .then((response) => {
        this.setState({
          loading: false,
        });
        return response.data;
      })
      .then((results) => {
        this.setState({ results: results });
        this.placeTemplates();
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  placeTemplates() {
    let max = Math.ceil(
      (window.innerWidth ? window.innerWidth : window.visualViewport.width) /
      300
    );
    let indexList = [];
    let totalRatio = 0;
    this.state.results.data.forEach((post, index) => {
      let ratio = parseInt(post.width) / parseInt(post.height);
      indexList.push({
        index,
        ratio,
      });
      totalRatio += ratio;
      if (totalRatio >= max) {
        let height =
          ((window.innerWidth
            ? window.innerWidth
            : window.visualViewport.width) -
            (indexList.length + 2) * 15) /
          totalRatio;
        indexList.forEach(({ index, ratio }) => {
          this.setState(function (state) {
            state.results.data[index].newheight = height;
            state.results.data[index].ratio = ratio;

            return {
              results: state.results,
            };
          });
        });
        indexList = [];
        totalRatio = 0;
      } else {
        this.setState(function (state) {
          state.results.data[index].newheight = 189.551;
          state.results.data[index].ratio = ratio;
          return {
            results: state.results,
          };
        });
      }
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.placeTemplates);
    this.doSearch();
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.state.search) {
      this.setState({ search: props.match.params.id });
      setTimeout(() => {
        this.doSearch();
      }, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.placeTemplates);
  }

  render() {
    return (
      <>
        {/* <Helmet>
					<title>{this.state.search ? this.state.search : "Subhakamana"}</title>
					{this.state.search && <meta name="keywords" content={this.state.search} />}
				</Helmet> */}
        <div className="bg-image text-center py-4">
          <SiteConsumer>
            {(site_setting) => (
              <>
                <h2 className="text-white mt-4  px-2">
                  {site_setting.site_tag_line}
                </h2>
                <div className="text-white">
                  {site_setting.site_description}
                </div>
              </>
            )}
          </SiteConsumer>
          <SearchSection history={this.props.history}></SearchSection>
          <div className="mt-5 mb-4 pt-4 d-flex justify-content-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.subhakamana&hl=en"
              className="bg-primary p-2 b-inline-block rounded shadow"
              target="_blank"
            >
              <img
                src="/static/media/Google-Play-Icon.png"
                className="width-150"
                alt="Download on Google Play Store"
              />
            </a>
            {/* <a href="#" target="_blank" className="b-inline-block">
							<img src="/static/media/App-store-Icon.png" className="width-150" alt="Download on Apple App Store" />
						</a> */}
          </div>
        </div>
        <section className="result-block">
          <div className="container-fluid border-bottom mb-3">
            <div className="text-center my-3">
              <p>
                Search results for{" "}
                <b>
                  {this.state.search
                    ? this.state.search
                    : "relavant templates."}
                </b>
              </p>
            </div>
          </div>
        </section>

        <section className="mb-5 pb-4">
          {!this.state.loading &&
            this.state.results.data &&
            !!this.state.results.data.length && (
              <div className="d-flex flex-wrap">
                {this.state.results.data.map((post) => (
                  <div
                    className="single-temp rounded"
                    key={post.slug}
                    style={{
                      height: post.newheight ? post.newheight + "px" : "auto",
                      width: post.ratio
                        ? post.ratio * post.newheight + "px"
                        : "auto",
                    }}
                    data-ratio={post.ratio}
                  >
                    <div className="inner-temp">
                      <Link to={`/view/${post.slug}`}>
                        <Image>
                          <img
                            className="rounded"
                            src={imageUrl + post.sample_image}
                            alt={post.caption}
                            loading="lazy"
                          />
                        </Image>
                        {/* <div className="overlay">
												<p className="text"> {post.caption} </p>
											</div> */}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            )}
          {this.state.loading && (
            <div className="col-12 text-center">
              <div className="h4">Loading</div>
            </div>
          )}
          {!this.state.loading &&
            this.state.results.data &&
            !this.state.results.data.length && (
              <div className="col-12 text-center">
                <div className="h4">No Result Found</div>
              </div>
            )}
          <Pagination
            current_page={this.state.results.current_page_no}
            total_page={this.state.results.total_page_no}
            onChange={this.doSearch}
          />
        </section>
      </>
    );
  }
}

export default Search;
