import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Image from "./image";
import { u_hostname_uploads } from "../variables";
class RecentPost extends Component {
	constructor(props) {
		super(props);
		this.state = {
			recentPosts: [],
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.placeTemplates = this.placeTemplates.bind(this);
	}

	handleSubmit(event) {
		event.preventDefault();
		this.props.history.push("search/dashain");
	}

	componentDidMount() {
		window.addEventListener("resize", this.placeTemplates);
		axios
			.get("/template/getRecentPost")
			.then((response) => {
				return response.data;
			})
			.then((posts) => {
				this.setState({
					recentPosts: posts,
				});
				setTimeout(() => this.placeTemplates(), 0);
			});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.placeTemplates);
	}

	placeTemplates() {
		let max = Math.ceil((window.innerWidth ? window.innerWidth : window.visualViewport.width) / 250);
		let indexList = [];
		let totalRatio = 0;
		this.state.recentPosts.forEach((post, index) => {
			let ratio = parseInt(post.width) / parseInt(post.height);
			indexList.push({
				index,
				ratio,
			});
			totalRatio += ratio;
			if (totalRatio >= max) {
				let height = ((window.innerWidth ? window.innerWidth : window.visualViewport.width) - (indexList.length + 2) * 15) / totalRatio;
				indexList.forEach(({ index, ratio }) => {
					this.setState(function (state) {
						state.recentPosts[index].newheight = height;
						state.recentPosts[index].ratio = ratio;
						return {
							recentPosts: state.recentPosts,
						};
					});
				});
				indexList = [];
				totalRatio = 0;
			} else {
				this.setState(function (state) {
					state.recentPosts[index].newheight = 189.551;
					state.recentPosts[index].ratio = ratio;
					return {
						recentPosts: state.recentPosts,
					};
				});
			}
		});
	}

	render() {
		const imageUrl = u_hostname_uploads;
		return (
			<section className="related-block mb-4">
				{this.state.recentPosts.length !== 0 && (
					<div>
						<div className="py-5">
							<div className="text-center w-100 h3 mb-4"> Featured Posts </div>
							<div className="d-flex flex-wrap">
								{this.state.recentPosts.map((post) => (
									<div
										className="single-temp rounded"
										key={post.slug}
										style={{
											height: post.newheight ? post.newheight + "px" : "auto",
											width: post.ratio ? post.ratio * post.newheight + "px" : "auto",
										}}
										data-ratio={post.ratio}>
										<div className="inner-temp">
											<Link to={`/view/${post.slug}`}>
												<Image>
													<img className="rounded" src={imageUrl + post.sample_image} alt={post.caption} loading="lazy" />
												</Image>
												{/* <div className="overlay">
													<p className="text"> {post.caption} </p>
												</div> */}
											</Link>
										</div>
									</div>
								))}
							</div>
							<div className="text-center mt-3">
								<Link className="btn btn-secondary rounded-pill" to={"/search"}>
									View More
								</Link>
							</div>
						</div>
					</div>
				)}
			</section>
		);
	}
}
export default RecentPost;
