import React, { Component } from "react";
import Navbar from "./navbar";

class Header extends Component {
  render() {
    return (
      <header className="shadow bg-white position-relative z-index-1000">
        <Navbar></Navbar>
      </header>
    );
  }
}
export default Header;
