import React from 'react';
export const FrameIcon = (props)=>(<svg version="1.1" style={props.style} id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 315.6 315.6" enableBackground="new 0 0 315.6 315.6" xmlSpace="preserve">
<g>
<path d="M275.2,62.2H40.4c-2.1,0-3.8,1.7-3.8,3.8v183.5c0,2.1,1.7,3.8,3.8,3.8h234.7c2.1,0,3.8-1.7,3.8-3.8V66
   C279,63.9,277.3,62.2,275.2,62.2z M256,222.8l-52.8-54.7c-0.6-0.6-1.5-0.6-2.2-0.1l-36.6,32.2l-46.8-57.7c-0.3-0.4-0.7-0.6-1.3-0.6
   c-0.5,0-0.9,0.2-1.2,0.6l-55.4,74.3V85.2H256L256,222.8L256,222.8z"/>
<path d="M197.9,140.7c10.4,0,18.8-8.4,18.8-18.8c0-10.4-8.4-18.8-18.8-18.8c-10.4,0-18.8,8.4-18.8,18.8
   C179.1,132.3,187.5,140.7,197.9,140.7z"/>
</g>
<rect x="18.8" y="18.8" fill="none" stroke="#000000" strokeWidth="12" strokeMiterlimit="10" width="278" height="278"/>
<rect x="268.6" width="47" height="47"/>
<rect width="47" height="47"/>
<rect y="273.3" width="47" height="47"/>
<rect x="268.6" y="273.3" width="47" height="47"/>
</svg>
)

export const RotateLeft = ()=>(
   <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 						width="20px" height="20px" viewBox="0 0 481.95 481.95" style={{enableBackground:"new 0 0 481.95 481.95"}}
	 						xmlSpace="preserve">
							<g>
								<g id="rotate-left">
									<path d="M114.75,191.25l-35.7-35.7C56.1,186.15,40.8,219.3,38.25,255h51C91.8,232.05,102,211.65,114.75,191.25z M89.25,306h-51
										c5.1,35.7,17.85,68.85,40.8,99.45l35.7-35.7C102,349.35,91.8,328.95,89.25,306z M114.75,441.15
										c30.6,22.949,63.75,35.699,99.45,40.8v-51c-22.95-2.55-43.35-12.75-63.75-25.5L114.75,441.15z M265.2,79.05V0L150.45,114.75
										L265.2,229.5v-99.45c71.4,12.75,127.5,73.95,127.5,150.45s-56.1,137.7-127.5,150.45v51c99.45-12.75,178.5-99.45,178.5-201.45
										S364.65,91.8,265.2,79.05z"/>
								</g>
							</g>
							</svg>
)

export const RotateRight = ()=>(
   <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 481.95 481.95" style={{enableBackground:"new 0 0 481.95 481.95"}} xmlSpace="preserve">
								<g>
									<g id="rotate-right">
										<path d="M331.5,114.75L216.75,0v79.05C117.3,91.8,38.25,175.95,38.25,280.5s79.05,188.7,178.5,201.45v-51
											C145.35,418.2,89.25,357,89.25,280.5s56.1-137.7,127.5-150.45v99.45L331.5,114.75z M443.7,255
											c-5.101-35.7-17.851-68.85-40.8-99.45l-35.7,35.7c12.75,20.4,22.95,40.8,25.5,63.75H443.7z M267.75,430.95v51
											c35.7-5.101,68.85-17.851,99.45-40.8l-35.7-35.7C311.1,418.2,290.7,428.4,267.75,430.95z M367.2,369.75l35.7,35.7
											c22.949-30.601,38.25-63.75,40.8-99.45h-51C390.15,328.95,379.95,349.35,367.2,369.75z"/>
									</g>
								</g>
							</svg>
);