export const loginFacebook = function (permissions, fields) {
    return new Promise(function (res, rej) {
        if (!window.FB) {
            rej('(ce)window.fb not defined');
        }
        window.FB.login(response => {
            facebookLoginCheck(fields).then(fb_res => {
                res(fb_res)
            }).catch((err) => {
                rej(err);
            });
        }, { scope: permissions });
    });
}

export const facebookLoginCheck = function (fields) {
    return new Promise((res, rej) => {
        if (!window.FB) {
            rej('(ce)window.fb not defined');
        }
        window.FB.getLoginStatus((response) => {
            if (response.status === "connected") {
                window.FB.api('/me?fields=id,' + fields, (fb_res) => {
                    res(fb_res);
                })
            } else {
                rej(false);
            }
        });
    });
}

export const shareFacebook = function (hrefUrl) {
    return new Promise(function (res, rej) {
        if (!window.FB) {
            rej('(ce)window.fb not defined');
        } else {
            window.FB.ui({
                method: 'share',
                href: hrefUrl,
                display:'popup'
            }, function (response) {
                if (response) {
                    if (response.error_message) {
                        if (response.error_message.error_code === 4201) {
                            rej('cancel');
                        }
                        rej('error');
                    } else {
                        res(response);
                    }
                } else {
                    rej('cancel')
                };
            });
        }
    });
}

export const sendFacebook = function (hrefUrl) {
    return new Promise(function (res, rej) {
        if (!window.FB) {
            rej('(ce)window.fb not defined');
        } else {
            window.FB.ui({
                method: 'send',
                link: hrefUrl,
                display:'popup'
            }, function (response) {
                console.log(response);
                if (response) {
                    if (response.error_message) {
                        rej('error');
                    } else {
                        res(response);
                    }
                } else {
                    rej('cancel');
                };
            });
        }
    });
}