import React from "react";
import SearchSection from "./searchSection";
export default function(props) {
  return (
    <div className="bg-flag text-center py-4">
      <h2 className="text-white mt-4">
        Wish your friends and loved ones Subhakamana
      </h2>
      <div className="text-white">Birthday, Wedding, Dashain, Tihar, Chath</div>
      <SearchSection history={props.history}></SearchSection>
      <div className="mt-5 mb-4 pb-3">
        <a href="#" className="mr-4 b-inline-block" target="_blank">
          <img
            src="/static/media/Google-Play-Icon.png"
            className="width-150"
            alt="Download on Google Play Store"
          />
        </a>
        <a href="#" target="_blank" className="b-inline-block">
          <img
            src="/static/media/App-store-Icon.png"
            className="width-150"
            alt="Download on Apple App Store"
          />
        </a>
      </div>
      <div className="text-white font-weight-bold py-5 my-5 shadow">
        <div style={{ fontSize: "150px", lineHeight: "150px" }}>404</div>
        <div style={{ fontSize: "50px", lineHeight: "50px" }}>Error</div>
      </div>
    </div>
  );
}
