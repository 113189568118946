import React from 'react';
import ReactDOM from 'react-dom';
import Router from './components/Router';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Router />, document.getElementById('root'));
window.installPrompt = null;
serviceWorker.register();
window.addEventListener('beforeinstallprompt',function(e){
    e.preventDefault();
    window.installPrompt = e;
    return false;
});
