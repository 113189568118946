/* eslint-disable eqeqeq */
import React, { Component } from "react";
import axios from "axios";
class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_name: "",
      user_phone: "",
      user_comment: "",
      user_name_check: false,
      user_phone_check: false,
      user_comment_check: false,
      thank_you_msg_status: false,
      thank_you_msg: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  submitFeedbackForm = () => {
    var username = this.state.user_name.trim();
    var userphone = this.state.user_phone.trim();
    var usercomment = this.state.user_comment.trim();
    if (username == "" && username.length <= 0) {
      this.setState({ user_name_check: true });
    } else {
      this.setState({ user_name_check: false });
    }
    if (userphone == "" && userphone.length <= 0) {
      this.setState({ user_phone_check: true });
    } else {
      this.setState({ user_phone_check: false });
    }
    if (usercomment == "" && usercomment.length <= 0) {
      this.setState({ user_comment_check: true });
    } else {
      this.setState({ user_comment_check: false });
    }
    if (username != "" && userphone != "" && usercomment != "") {
      axios
        .post("/user/feedback", { username, userphone, usercomment })
        .then(res => {
          if (res.status == 200) {
            this.setState({
              user_name: "",
              user_phone: "",
              user_comment: "",
              user_name_check: false,
              user_phone_check: false,
              user_comment_check: false,
              thank_you_msg_status: true,
              thank_you_msg: "Thank you for your feedback."
            });
          } else {
            this.setState({
              thank_you_msg_status: true,
              thank_you_msg:
                "Sorry! we could not take your feedback at the moment."
            });
          }
        });
    }
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div className="bg-flag">
        <div className="container py-5">
          <h2 className="text-white text-center">Feedback</h2>
          <div className="row">
            <div className="col-12 col-md-6">
              <img
                src="/static/media/feedback.svg"
                alt="Feedback is good for us"
              />
            </div>
            <div className="col-12 col-md-6 py-5">
              <form className="shadow">
                <div className="form-group pb-3">
                  <input
                    type="text"
                    className="rounded-pill form-control px-4"
                    id="email"
                    name="user_name"
                    required
                    placeholder="Full Name"
                    value={this.state.user_name}
                    onChange={this.handleInputChange}
                  />
                  {this.state.user_name_check && (
                    <label className="text-white">
                      Please Enter your full name
                    </label>
                  )}
                </div>
                <div className="form-group pb-3">
                  <input
                    type="tel"
                    className=" rounded-pill form-control px-4"
                    id="phone"
                    name="user_phone"
                    value={this.state.user_phone}
                    onChange={this.handleInputChange}
                    maxLength="10"
                    placeholder="Phone no"
                  />
                  {this.state.user_phone_check && (
                    <label className="text-white">Phone no. is required</label>
                  )}
                </div>
                <div className="form-group pb-3">
                  <textarea
                    className="form-control br-20 px-4"
                    id="comment"
                    rows="5"
                    name="user_comment"
                    value={this.state.user_comment}
                    onChange={this.handleInputChange}
                    placeholder="Feedback"
                  />
                  {this.state.user_comment_check && (
                    <label className="text-white">Feedback is required</label>
                  )}
                </div>
                <button
                  type="button"
                  className="btn btn-secondary rounded-pill px-4"
                  onClick={this.submitFeedbackForm}>
                  Submit
                </button>
              </form>
              {this.state.thank_you_msg_status && (
                <div className="alert alert-info mt-1">
                  <strong>{this.state.thank_you_msg}</strong>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Feedback;
