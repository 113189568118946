import React, { Component } from "react";
import axios from "axios";
import Image from "./image";
import { Link } from "react-router-dom";
import RecentPost from "./recentPost";
import SearchSection from "./searchSection";
import queryString from "query-string";
import { u_hostname_uploads, u_hostname_preview } from "../variables";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
export default class Start extends Component {
	constructor(props) {
		super(props);
		this.state = {
			template: {},
			loading: true,
			preview_image: "",
			modelOpen: true,
			shared_image: "",
			url: props.match.params.url,
			user: null,
		};
		this.sharedImageError = this.sharedImageError.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
	}

	componentDidMount() {
		let slug = this.props.match.params.slug;
		let url_image = queryString.parse(this.props.location.search);
		if (slug && this.state.url) {
			if (this.state.url) {
				axios.get("/previewInfo?url=" + this.state.url).then(res => {
					let { facebook_user, image, alt_image } = res.data;
					this.setState({
						shared_image: u_hostname_preview + (alt_image || image),
						user: facebook_user,
					});
				});
			}
			axios
				.get("/template/template/" + slug)
				.then(res => {
					this.setState({
						template: res.data,
						loading: false,
						preview_image: res.data.sample_image,
					});
				})
				.catch(err => {
					this.setState({
						loading: false,
					});
					console.log(err);
				});
		} else if (this.state.url) {
			axios.get(`/preview/${this.state.url}`).then(res => {
				let { facebook_user, image, alt_image, Template: template } = res.data;
				this.setState({
					shared_image: u_hostname_preview + (alt_image || image),
					user: facebook_user,
					template,
					loading: false,
					preview_image: template.sample_image,
				});
			});
		} else {
			this.setState({ shared_image: u_hostname_preview + url_image.img, loading: false });
		}
	}
	sharedImageError() {
		this.setState(({ preview_image }) => {
			return {
				shared_image: u_hostname_uploads + preview_image,
			};
		});
	}
	toggleModal() {
		this.setState(({ modelOpen }) => {
			return {
				modelOpen: !modelOpen,
			};
		});
	}
	render() {
		const imageUrl = u_hostname_uploads;
		return (
			<>
				<div className="bg-flag text-center py-4 pb-300-px">
					<h2 className="text-white mt-4">Wish your friends and loved ones Subhakamana</h2>
					<div className="text-white">Birthday, Wedding, Dashain, Tihar, Chath</div>
					<SearchSection history={this.props.history}></SearchSection>
					<div className="mt-5 mb-4 pb-3">
						<a href="https://play.google.com/store/apps/details?id=com.subhakamana&hl=en" className="mr-4 b-inline-block" target="_blank">
							<img src="/static/media/Google-Play-Icon.png" className="width-150" alt="Download on Google Play Store" />
						</a>
						{/* <a href="#" target="_blank" className="b-inline-block">
							<img src="/static/media/App-store-Icon.png" className="width-150" alt="Download on Apple App Store" />
						</a> */}
					</div>
				</div>
				{!this.state.loading && !!Object.keys(this.state.template).length && (
					<section className="target-block">
						<div className="container  mb-5 mt-m-250 max-width-700" style={{ paddingLeft: "30px", paddingRight: "30px" }}>
							<div className="row mb-4">
								<h2 className="col h3 text-white p-0">{this.state.template.caption}</h2>
								<div>
									<Link className="btn btn-secondary rounded-pill shadow" to={"/view/" + this.state.template.slug}>
										<i className="fas fa-pencil-alt"></i> Create
									</Link>
								</div>
							</div>
							<div className="row bg-light rounded shadow py-3">
								<div className="col-12 image">
									<Image>
										<img src={imageUrl + this.state.preview_image} alt={this.state.template.caption} />
									</Image>
								</div>
							</div>
						</div>
					</section>
				)}
				{this.state.loading && (
					<div className="text-center">
						<div className="h4">Loading</div>
					</div>
				)}
				{!this.state.loading && !Object.keys(this.state.template).length && (
					<div className="text-center">
						<div className="h2">Not Found</div>
					</div>
				)}
				<RecentPost />
				{!this.state.loading && this.state.modelOpen && this.state.shared_image && (
					<Modal isOpen={this.state.modelOpen} toggle={this.toggleModal} size="lg">
						<ModalHeader toggle={this.toggleModal}>
							{this.state.user && (
								<div className="d-flex align-items-center">
									<img
										src={this.state.user.image}
										alt={this.state.user.name}
										style={{
											height: "75px",
											width: "75px",
											borderRadius: "50%",
										}}
									/>
									<div className="ml-3">
										<div className="text-primary">Subhakamana from your friend</div>
										<span className="font-weight-bold h4">{this.state.user.name}</span>
									</div>
								</div>
							)}
						</ModalHeader>
						<ModalBody>
							<Image>
								<img src={this.state.shared_image} alt="" onError={this.sharedImageError} />
							</Image>
						</ModalBody>
					</Modal>
				)}
			</>
		);
	}
}
