import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Search from "./search";
import View from "./view";
import Start from "./start";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import App from "../App";
import Feedback from "./feedback";
import Layout from "./layout/navbarLayout";
import Error404 from "./error404";
import Axios from "axios";
import { CategoryProvider } from "../menu";
import { SiteProvider } from "../sitecontext";

export default class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      site_setting: {},
    };
  }
  componentDidMount() {
    Axios.get("/settings").then((response) => {
      this.setState({
        site_setting: response.data.data,
      });
    });
    Axios.get("/menu/menu")
      .then((response) => {
        return response.data;
      })
      .then((category) => {
        this.setState({ category });
      });
  }

  render() {
    return (
      <SiteProvider value={this.state.site_setting}>
        <CategoryProvider value={this.state.category}>
          <BrowserRouter>
            <Route
              render={() => (
                <Layout>
                  <Switch>
                    <Route exact path="/view/:slug" component={View} />
                    <Route exact path="/" component={App} />
                    <Route exact path="/search/:id?" component={Search} />
                    <Route exact path="/start/:slug" component={Start} />
                    <Route exact path="/start/:slug/:url" component={Start} />
                    <Route exact path="/user/feedback" component={Feedback} />
                    <Route
                      exact
                      path="/privacy-policy"
                      component={PrivacyPolicy}
                    />
                    <Route exact path="/:url" component={Start} />
                    <Route component={Error404} />
                  </Switch>
                </Layout>
              )}
            />
          </BrowserRouter>
        </CategoryProvider>
      </SiteProvider>
    );
  }
}
