/* eslint-disable eqeqeq */
import React, { Component } from "react";
import axios from "axios";

export default class SubscribeBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_email: "",
			emailCheck: false,
			emailMsg: "",
		};
		this.handleEmail = this.handleEmail.bind(this);
		this.subscribeUser = this.subscribeUser.bind(this);
	}
	handleEmail(e) {
		var email = e.target.value;
		this.setState({ user_email: email });
	}

	subscribeUser(e) {
		e.preventDefault();
		var user_email = this.state.user_email;
		if (user_email == "") {
			this.setState({
				emailCheck: true,
				emailMsg: "Email address is required.",
			});
		} else if (user_email != "") {
			// eslint-disable-next-line no-useless-escape
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+(?:[A-Z]{2}|com|org|net|gov|np|info|in|edu|us|uk|co)))$/;
			var result = re.test(user_email.toLowerCase());
			if (result) {
				this.setState({ emailCheck: false, emailMsg: "" });
				axios.post("/subscribe", { email: this.state.user_email }).then(res => {
					var response = res;
					console.log(response);
					if (response.status == 200) {
						this.setState({
							emailCheck: true,
							emailMsg: "Thank You! We will update you.",
						});
						setTimeout(() => {
							this.setState({ emailCheck: false, emailMsg: "" });
						}, 5000);
					} else if (response.status == 422) {
						this.setState({
							emailCheck: true,
							emailMsg: "Email address is required or not valid.",
						});
					} else if (response.status == 500) {
						this.setState({ emailCheck: true, emailMsg: response.data.msg });
					}
				});
			} else {
				this.setState({
					emailCheck: true,
					emailMsg: "Email address is not valid.",
				});
			}
		}
	}

	render() {
		const emailFieldRequired = this.state.emailCheck ? <small className="text-danger font-weight-bold w-100">{this.state.emailMsg}</small> : "";
		return (
			<section className="bg-secondary">
				<div className="py-5 container max-width-700">
					<div className="inner-block py-5 text-white text-center">
						<div className="h2 w-100">Subscribe Us</div>
						<div>Subscribe Us to Get Notify</div>
						<form className="d-flex h2 pt-4 w-100 shadow" onSubmit={this.subscribeUser}>
							<input
								className="form-control search rounded-pill px-4"
								type="email"
								placeholder="Email Address"
								aria-label="email"
								aria-describedby="email"
								required
								value={this.state.user_email}
								onChange={this.handleEmail}
							/>
							{emailFieldRequired && <span className="text-white">{emailFieldRequired}</span>}
							<button className="btn btn-primary search-button ml-2 py-1 rounded-pill" type="submit">
								Subscribe
							</button>
						</form>
						<div className="mt-5">
							<a href="https://play.google.com/store/apps/details?id=com.subhakamana&hl=en" className="mr-4 b-inline-block" target="_blank">
								<img src="/static/media/Google-Play-Icon.png" className="width-150" alt="Download on Google Play Store" />
							</a>
							{/* <a href="#" target="_blank" className="b-inline-block">
                <img
                  src="/static/media/App-store-Icon.png"
                  className="width-150"
                  alt="Download on Apple App Store"
                />
              </a> */}
						</div>
					</div>
				</div>
			</section>
		);
	}
}
