import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
export default function ({ onDone, onClose, title, children }) {
    return (
        <Modal isOpen={true}>
            {!!title && <ModalHeader>{title}</ModalHeader>}
            <ModalBody className="text-center">
                {!!onClose && <ModelCloseButton type="button" onClick={onClose} top={title?-45:10}>
                    <i className="fa fa-close"></i>
                </ModelCloseButton>
                }
                {
                    children
                }
            </ModalBody>
            {!!onDone &&
                <ModalFooter>
                    <button onClick={onDone} className="btn btn-primary">Done</button>
                </ModalFooter>
            }
        </Modal>
    );
}


let ModelCloseButton = styled.button`
	background:none;
	background-color:#000;
	color:#fff;
	border-radius:50%;
	position:absolute;
	top:${({top})=>top}px;
	border:0;
	right:10px;
`;
