export const getFontCSS = function (font_array, imageUrl) {
  return font_array
    .map(function (font) {
      return `
        @font-face{
            font-family:'${font.name}';
            src:url('${imageUrl + font.attachment}');
        }
        `;
    })
    .join(" ");
};

export const getSvgTextElCss = function (el) {
  let style = window.getComputedStyle(el);
  let { fontSize, fontWeight, fontFamily, fill, textAnchor } = style;
  return `
        font-weight:${fontWeight};
        font-family:${fontFamily};
        color:${fill};
    `;
};
export const replaceTextWithDiv = function (svg, field, replacableText) {
  let text = svg.querySelector(`#${field.id}`);
  if (text) {
    if (
      (text.tagName !== "text" && text.tagName !== "g") ||
      text.tagName === "foreignObject"
    ) {
      let replacingText = replacableText || field.placeholder || "";
      text.children[0].innerHTML = "";
      replacingText.split("\\n").forEach(function (txt) {
        let subDiv = document.createElement("div");
        subDiv.innerText = txt;
        text.children[0].append(subDiv);
      });
    } else {
      text.removeAttribute("id");
      if (text.tagName === "g") {
        text = text.querySelector("text");
      }
      if (!text) {
        return;
      }
      let textEl = !!text.children[0] ? text.children[0] : text;
      let css = getSvgTextElCss(textEl);
      css = css + "text-align:" + (field.align ? field.align : "left");
      if (field.fontSize) {
        css = css + ";font-size:" + field.fontSize + ";";
      }
      let text_rect = text.previousElementSibling;
      let width = text_rect.width.animVal.value;
      let height = text_rect.height.animVal.value;
      let x = text_rect.x.animVal.value;
      let y = text_rect.y.animVal.value;

      var foreign = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "foreignObject"
      );
      foreign.setAttribute("width", width);
      foreign.setAttribute("height", height);
      foreign.setAttribute("x", x);
      foreign.setAttribute("y", y);
      foreign.setAttribute("id", field.id);

      var iDiv = document.createElement("div");

      iDiv.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");
      iDiv.setAttribute("contenteditable", true);
      iDiv.setAttribute("style", css);
      if (field.placeholder) {
        field.placeholder.split("\\n").forEach((text) => {
          let subDiv = document.createElement("div");
          subDiv.innerText = text;
          iDiv.append(subDiv);
        });
      }
      foreign.appendChild(iDiv);
      svg.appendChild(foreign);
      text_rect.remove();
      text.remove();
    }
  }
};
