import React, { Component } from "react";
import logo from "../image/subhakamana_logo.png";
import subhakamana from "../image/subhakamana.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { CategoryConsumer } from "../menu";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    function megaMenu(menus) {
      return (
        <DropdownMenu
          className="bg-white dropdown-menu rounded-0  mt-0 w-100 row"
          aria-labelledby="navbarDropdown"
        >
          <ul className="mega-menu p-0">
            {menus.map(function (menu) {
              return (
                <li key={menu.slug}>
                  <Link
                    to={`/search/${menu.slug}`}
                    className="p-2 pl-4 w-100 d-inline-block"
                  >
                    {menu.name}
                  </Link>
                  <i className="fa fa-angle-down nav-icon d-lg-none"></i>
                  <div className="nav-sub-menu py-3">
                    {menu.childs ? getSubMenu(menu) : ""}
                  </div>
                </li>
              );
            })}
          </ul>
        </DropdownMenu>
      );
    }

    function getSubMenu(menu) {
      return (
        <ul className="d-flex flex-wrap w-100 p-0">
          {menu.childs.map(function (subMenu) {
            return (
              <li className="col-4 pb-3 pr-5 border-right" key={subMenu.slug}>
                <Link
                  to={`/search/${subMenu.slug}`}
                  className="h5 mb-2 d-inline-block font-weight-bold text-secondary"
                >
                  {" "}
                  {subMenu.name}
                </Link>
                <ul className="pl-0">
                  {subMenu.childs && subMenu.childs
                    ? subMenu.childs.map(function (child) {
                        return (
                          <li key={child.slug}>
                            <Link to={`/search/${child.slug}`}>
                              {child.name}
                            </Link>
                          </li>
                        );
                      })
                    : false}
                </ul>
              </li>
            );
          })}
        </ul>
      );
    }
    return (
      <CategoryConsumer>
        {(menus) => (
          <WithNavToggleIcon>
            {/* {JSON.parse(menus)} */}
            <Navbar expand="lg" className="py-0">
              <NavbarBrand
                tag={Link}
                to="/"
                className="d-flex align-items-center py-2 mr-0"
              >
                <img src={logo} alt="subhakamana" className="logo" />
                <span className="py-2 logo-name border-secondary">
                  <img src={subhakamana} alt="Subhakamana" />
                </span>
              </NavbarBrand>
              <NavbarToggler onClick={this.toggle} alt="navbar toggle icon" />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      <i className="fas fa-bars"></i> All Categories
                    </DropdownToggle>
                    {megaMenu(menus)}
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLink tag={Link} to="/user/feedback">
                      Feedback
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <div
                      className="fb-like"
                      style={{ margin: "20px 0px" }}
                      data-href="https://www.facebook.com/Subhakamana-109150540483063/"
                      data-width=""
                      data-layout="button_count"
                      data-action="like"
                      data-size="small"
                      data-show-faces="true"
                      data-share="true"
                    ></div>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </WithNavToggleIcon>
        )}
      </CategoryConsumer>
    );
  }
}

export default Navigation;

const WithNavToggleIcon = styled.div`
width:100%;
  .navbar-toggler-icon:before{
      content:''; 
      height:3px;
      width:100%
      display:inline-block;
      background-color:#999;
      box-shadow:0 8px 0 #999, 0 -9px 0 #999
    }
      h4 > a{
      color: #000;
    }
  }
`;
