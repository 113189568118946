import "react-input-range/lib/css/index.css";
import InputRange from "react-input-range";
import styled from "styled-components";
import React, { Component } from "react";
import { RotateLeft, RotateRight } from "./icons";

export default class ImageCrop extends Component {
  canvas = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      natural: true,
      ih: 210,
      ch: props.canvas_height,
      cw: props.canvas_width,
      iw: 400,
      angle: 0,
      ctw: 0,
      cth: 0,
      image_slider: 0
    };
    this.rotateLeft = this.rotateLeft.bind(this);
    this.rotateRight = this.rotateRight.bind(this);
    this.onDone = this.onDone.bind(this);
  }
  onDone() {
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    canvas.width = this.props.width;
    canvas.height = this.props.height;
    context.drawImage(
      this.canvas.current,
      0,
      0,
      this.props.width,
      this.props.height
    );
    this.props.onDone(canvas.toDataURL("image/png"));
  }
  rotateLeft() {
    this.drawRotated(-90);
  }
  rotateRight() {
    this.drawRotated(+90);
  }

  draw() {
    setTimeout(() => {
      let { ih, iw, cth, ctw } = this.state;
      this.canvasCtx.drawImage(this.state.image, ctw, cth, iw, ih);
    });
  }

  drawRotated(degrees) {
    let { ch, cw, ih, iw, cth, ctw, natural } = this.state;
    let angle = this.state.angle + degrees;
    if (angle === 360 || angle === -360) {
      angle = 0;
    }
    natural = degrees ? !natural : natural;
    this.canvasCtx.rotate((degrees * Math.PI) / 180);
    if (degrees) {
      if (natural) {
        if (ch !== cw) {
          let scale = 1;
          if (ch > iw) {
            scale = 1 + (1 - ch / iw) / (ch / iw);
          } else {
            scale = 1 + (1 - cw / ih) / (cw / ih);
          }
          this.canvasCtx.scale(scale, scale);
        }
      } else {
        if (ch !== cw) {
          if (ch > iw) {
            this.canvasCtx.scale(ch / iw, ch / iw);
          } else {
            this.canvasCtx.scale(cw / ih, cw / ih);
          }
        }
      }
    }
    if (natural) {
      if (this.state.angle === 0) {
        cth = (-(ih - ch) * this.state.image_slider) / 100;
        ctw = (-(iw - cw) * this.state.image_slider) / 100;
      } else {
        cth = -ih - ((-ih + ch) * this.state.image_slider) / 100;
        ctw = -iw + ((iw - cw) * this.state.image_slider) / 100;
      }
    } else {
      if (this.state.angle === 270 || this.state.angle === -90) {
        if (ch === cw && ih > iw) {
          cth = ((ch - ih) * this.state.image_slider) / 100;
          ctw = -iw;
        } else {
          cth = 0;
          ctw = -iw + ((iw - (ih * ch) / cw) * this.state.image_slider) / 100;
        }
      } else {
        if (ch === cw && ih > iw) {
          cth = -(ih - ((ih - ch) * this.state.image_slider) / 100);
          ctw = 0;
        } else {
          cth = -ih;
          ctw = (-(iw - (ih * ch) / cw) * this.state.image_slider) / 100;
        }
      }
    }
    this.setState({
      natural,
      angle,
      ctw,
      cth
    });
    this.draw();
  }
  setCalculatedState() {
    let image = new Image();
    image.onload = () => {
      let canvas_width = 800;
      let canvas_height = (800 / this.props.width) * this.props.height;
      let [img_width, img_height, img_x, img_y] = this.convertImageHtWt(
        image.width,
        image.height,
        canvas_height,
        canvas_width
      );
      this.setState({
        ctw: img_x,
        cth: img_y,
        ih: img_height,
        iw: img_width,
        image,
        cw: canvas_width,
        ch: canvas_height
      });
      setTimeout(() => {
        this.drawRotated(0);
      }, 0);
    };
    image.src = this.props.image;
  }
  componentDidMount() {
    this.canvasCtx = this.canvas.current.getContext("2d");
    this.setState({ window_width: window.innerWidth });
    this.setCalculatedState();
  }
  componentDidUpdate(prevProp, prevState) {
    if (prevProp.image !== this.props.image) {
      this.setCalculatedState();
    }
    if (
      prevState.image_slider !== this.state.image_slider ||
      prevState.natural !== this.state.natural
    ) {
      this.drawRotated(0);
    }
  }

  convertImageHtWt(iw, ih, ch, cw) {
    let imgH = (cw / iw) * ih;
    if (imgH <= ch) {
      let imgW = (ch / ih) * iw;
      return [imgW, ch, 0, 0];
    }
    return [cw, imgH, 0, 0];
  }
  render() {
    return (
      <>
        <button
          onClick={this.rotateLeft}
          className="btn btn-light border shadow">
          <RotateLeft />
        </button>
        <button
          onClick={this.rotateRight}
          className="btn btn-light border shadow ml-2">
          <RotateRight />
        </button>
        <button onClick={this.onDone} className="btn btn-primary shadow ml-2">
          <i className="fa fa-check"></i>
        </button>
        <InputRange
          maxValue={100}
          minValue={0}
          value={this.state.image_slider}
          onChange={value => this.setState({ image_slider: value })}
        />
        <CanvasWrapper
          windowWidth={this.state.window_width}
          canvasHeight={this.state.ch}
          canvasWidth={this.state.cw}>
          <canvas
            ref={this.canvas}
            width={this.state.cw}
            height={this.state.ch}></canvas>
        </CanvasWrapper>
      </>
    );
  }
}

const CanvasWrapper = styled.div`
  width: 100%;
  position: relative;
  height: calc(
    ${props =>
        props.windowWidth < 500
          ? ((props.windowWidth - 50) / 800) * props.canvasHeight
          : props.canvasHeight * 0.585}px + 70px
  );
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left top;
    transform: scale(
      ${props =>
        props.windowWidth < 500 ? (props.windowWidth - 50) / 800 : 0.585}
    );
  }
`;
