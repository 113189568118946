import React, { Component } from "react";
import axios from "axios";
import "font-awesome/css/font-awesome.min.css";
import styled from "styled-components";
import { FacebookProvider } from "react-facebook";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { WhatsappShareButton, LinkedinShareButton } from "react-share";
import { u_hostname_uploads } from "../variables";
import LoadingOverlay from "react-loading-overlay";
import { Alert } from "reactstrap";
import { FrameIcon } from "./icons";
import { getFontCSS, replaceTextWithDiv } from "../bin/svg";
import {
  facebookLoginCheck,
  loginFacebook,
  shareFacebook,
  sendFacebook,
} from "../bin/facebook";
import { isMobile, isMobileSharable } from "../bin/device";
import InputRange from "react-input-range";
import CustomeModal from "./modal";
import CropImage from "./imageCropNew";
import urlBase64ToUint8Array from "../bin/urlBase64ToUint8Array";
import Pagination from "./pagination";

const imageUrl = u_hostname_uploads;
class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_search: props.match.params.slug,
      svg: [],
      message: "",
      image: "",
      new_image: "",
      convertedImage: "",
      new_svg: "",
      loading: false,
      template: null,
      loadingActive: false,
      loadingText: "Loading...",
      category: "",
      height: 0,
      width: 0,
      modal: false,
      isWeb: true,
      successOrReject: false,
      facebookLoginModal: false,
      email: "",
      fb: false,
      cropImage: null,
      errModal: false,
      textFocus: false,
      selectedText: null,
      fontSize_slider: 16,
      messages_related_to_templates: [],
      mrtt_page: 0,
      mrtt_pageSize: 5,
      mrtt_total: 0,
      scrollMounted: false,
      fetchingMessages: false,
    };
    this.handleConvertSvg = this.handleConvertSvg.bind(this);
    this.closeLoginModal = this.closeLoginModal.bind(this);
    this.fblogin = this.fblogin.bind(this);
    this.fbShare = this.fbShare.bind(this);
    this.cropDone = this.cropDone.bind(this);
    this.closeErrModal = this.closeErrModal.bind(this);
    this.textFocus = this.textFocus.bind(this);
    this.textBlur = this.textBlur.bind(this);
    this.getMessagesRelatedToTemplate =
      this.getMessagesRelatedToTemplate.bind(this);
    this.changeMessage = this.changeMessage.bind(this);
    this.checkAndAddScrollEvent = this.checkAndAddScrollEvent.bind(this);
  }

  handleConvertSvg() {
    let svg_image = document
      .querySelector("#editTemplate")
      .getElementsByTagName("svg")[0];
    let requestData = JSON.parse(this.state.template.fields).map(
      ({ id, type }) => {
        let element = svg_image.querySelector("#" + id);
        return {
          el: (!!element && element.outerHTML) || " ",
          id,
          type,
        };
      }
    );
    this.setState({
      new_svg: svg_image,
      loading: true,
      loadingActive: true,
      loadingText: "Preparing your image.Please wait!!!",
    });
    axios
      .post("/template/convertImage", {
        requestData,
        title: this.props.match.params.slug,
        userId: this.state.fb.id,
        height: this.state.height,
        width: this.state.width,
        t_id: this.state.template.id,
      })
      .then((response) => {
        return response.data.link;
      })
      .then((results) => {
        this.setState({
          convertedImage: results,
          loading: false,
          loadingActive: false,
          loadingText: "",
        });
        let fields = JSON.parse(this.state.template.fields);
        let svg = document.getElementById("editTemplate").querySelector("svg");
        fields.forEach((field) => {
          if (field.type === "image" || field.type === "fb_image") {
            let image = svg.querySelector(`#${field.id}`);
            image.onclick = null;
          } else if (
            field.type === "text" ||
            field.type === "name" ||
            field.type === "author"
          ) {
            let text = svg.querySelector(`#${field.id} div`);
            text.removeAttribute("contenteditable");
          }
        });
        if ("Notification" in window) {
          // console.log('requesting notification permission')
          Notification.requestPermission((action) => {
            if (action === "granted") {
              // console.log('notification granted')
              if ("serviceWorker" in navigator) {
                this.configurePushSub();
              }
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          errModal: true,
          loading: false,
          loadingActive: false,
          loadingText: "",
        });
      });
  }

  configurePushSub() {
    let vapidPublic =
      "BBa-58_48sqU1aKfCfc5bUdml0ZfrUiQilu4DcY5yURpRDWgPWy1o494RtNbBE80tdP9XgFQM65vE-64TFrTskg";
    let convertedVapid = urlBase64ToUint8Array(vapidPublic);
    let reg;
    navigator.serviceWorker.ready
      .then((swreg) => {
        reg = swreg;
        return swreg.pushManager.getSubscription();
      })
      .then((sub) => {
        if (!sub) {
          return reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapid,
          });
        }
        return false;
      })
      .then((newSub) => {
        if (newSub !== false) {
          axios
            .post("/subscriber", {
              data: newSub,
            })
            .then(function () {
              let options = {
                body: "Subscribed to Subhakamana",
                icon: "/icons/android-icon-96x96.png",
                dir: "ltr",
                lang: "en-US",
                vibrate: [100, 100, 200],
                badge: "/icons/android-icon-96x96.png",
                tag: "subhakamana",
                renotify: true,
                data: "/",
                actions: [
                  {
                    action: "confirm",
                    title: "Visit Subhakamana",
                  },
                  {
                    action: "cancel",
                    title: "Cancel",
                  },
                ],
              };
              navigator.serviceWorker.ready.then(function (reg) {
                reg.showNotification(
                  "Thanks For Subscribing to Subhakamana",
                  options
                );
              });
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  closeLoginModal() {
    this.setState({
      facebookLoginModal: false,
    });
  }
  closeErrModal() {
    this.setState({
      errModal: false,
    });
  }

  fbShare() {
    var hrefUrl = this.state.convertedImage;
    if (!this.state.fb) {
      this.fblogin(this.fbShare);
    } else {
      shareFacebook(hrefUrl)
        .then((res) => {
          if (window.installPrompt) {
            window.installPrompt.prompt();
            window.installPrompt.userChoice.then(function (choice) {
              // if (choice.outcome === "dismissed") {
              // 	console.log("user cancelled installed");
              // } else {
              // 	console.log("user added to home screen");
              // }
              window.installPrompt = null;
            });
          }
          this.setState({ successOrReject: true });
          axios
            .post("/user/status_share", {
              platform: "facebook_share",
              status: "success",
            })
            .then((res) => {})
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          console.log(err);
          if (err === "error" || err === "cancel") {
            axios
              .post("/user/status_share", {
                platform: "facebook_share",
                status: err,
              })
              .then((res) => {})
              .catch((err) => console.log(err));
          } else {
            console.log(err);
          }
        });
    }
  }
  fblogin(callback = undefined) {
    loginFacebook("email", "name,email,picture.width(800).height(800)")
      .then((fb_res) => {
        this.setState({ fb: fb_res, facebookLoginModal: false });
        let {
          id,
          email,
          name,
          picture: {
            data: { url },
          },
        } = fb_res;
        axios
          .post("/template/saveUser", { email, name, image: url, fb_id: id })
          .then(function () {
            // console.log("fblogin save user");
            if (typeof callback === "function") {
              callback();
            }
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ facebookLoginModal: true, fb: false });
      });
  }

  mobileShare = async () => {
    const TitleConst = "Subhakamana";
    const URLConst = this.state.convertedImage;
    try {
      await navigator.share({ title: TitleConst, url: URLConst });
      axios
        .post("/user/status_share", {
          platform: "app_share",
          status: "success",
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log("Error sharing: " + error);
      axios
        .post("/user/status_share", { platform: "app_share", status: "error" })
        .catch((err) => console.log(err));
    }
  };

  sharePrivatelyFriends = () => {
    var hrefUrl = this.state.convertedImage;
    if (!this.state.fb) {
      this.fblogin(this.sharePrivatelyFriends);
    } else {
      sendFacebook(hrefUrl)
        .then((res) => {
          this.setState({ successOrReject: true });
          axios
            .post("/user/status_share", {
              platform: "facebook_messenger_share",
              status: "success",
            })
            .then((res) => {})
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          if (err === "error" || err === "cancel") {
            axios
              .post("/user/status_share", {
                platform: "facebook_messenger_share",
                status: err,
              })
              .then((res) => {})
              .catch((err) => console.log(err));
          } else {
            console.log(err);
          }
        });
    }
  };

  handleShareByReactShare = () => {
    this.setState({ successOrReject: true });
  };
  onAlertDismiss = () => {
    this.setState({ successOrReject: false });
  };
  componentDidUpdate(prevProp, prevState) {
    if (prevState.fontSize_slider !== this.state.fontSize_slider) {
      /* Need to fix this */
      this.state.selectedText.style["font-size"] =
        this.state.fontSize_slider + "px";
    }
    if (prevState.template !== this.state.template) {
      facebookLoginCheck("name,email,picture.width(800).height(800)")
        .then((fb_res) => {
          this.setState({ fb: fb_res, facebookLoginModal: false });
        })
        .catch(() => {
          this.setState({ fb: false, facebookLoginModal: true });
        });
    }
    if (
      !this.state.convertedImage &&
      (prevState.template !== this.state.template ||
        prevState.fb !== this.state.fb)
    ) {
      let fields = JSON.parse(this.state.template.fields);
      let svg = document.getElementById("editTemplate").querySelector("svg");
      fields.forEach((field) => {
        if (
          field.type === "text" ||
          field.type === "author" ||
          field.type == "placeholder"
        ) {
          replaceTextWithDiv(svg, field);
        } else if (field.type === "name") {
          let { name } = this.state.fb;
          replaceTextWithDiv(svg, field, name);
        } else if (field.type === "fb_image") {
          if (!!this.state.fb) {
            let image = svg.querySelector(`#${field.id}`);
            let reader = new FileReader();
            axios
              .get(this.state.fb.picture.data.url, { responseType: "blob" })
              .then((res) => {
                reader.readAsDataURL(res.data);
                reader.onloadend = () => {
                  let loadimage = new Image();
                  loadimage.onload = () => {
                    let canvas = document.createElement("canvas");
                    canvas.width = canvas.height = Math.max(
                      parseInt(image.getAttribute("width")),
                      parseInt(image.getAttribute("height"))
                    );
                    let context = canvas.getContext("2d");
                    context.drawImage(
                      loadimage,
                      0,
                      0,
                      canvas.width,
                      canvas.height
                    );
                    document
                      .querySelector(`#${field.id}`)
                      .setAttribute(
                        "xlink:href",
                        canvas.toDataURL("image/png")
                      );
                  };
                  loadimage.src = reader.result;
                };
              });
          }
          this.makeImageChangable(svg, field);
        } else if (field.type === "image") {
          this.makeImageChangable(svg, field);
        }
      });
    }
    if (
      !this.state.scrollMounted &&
      this.state.messages_related_to_templates.length > 0
    ) {
      this.checkAndAddScrollEvent();
      // console.log('comonent updatte')
    }
  }

  makeImageChangable(svg, field) {
    let image = svg.querySelector(`#${field.id}`);
    let canvas_width = parseInt(image.getAttribute("width"));
    let canvas_height = parseInt(image.getAttribute("height"));
    this.setState({
      canvas_height,
      canvas_width,
    });
    image.setAttribute("preserveAspectRatio", "xMinYMin slice");
    image.onclick = (e) => {
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("name", field.id);
      input.disabled = false;
      input.onchange = (e1) => {
        input.onchange = null;
        let reader = new FileReader();
        let file = e1.target.files[0];
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({
            selected_item: e.target,
            modal: true,
            cropImage: reader.result,
          });
        };
      };
      input.click();
    };
  }

  goToSearchWithCategory = () => {
    this.props.history.push("/search/" + this.state.category);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      loadingText: "Fetching Image...",
      loadingActive: true,
      isWeb: !isMobile(),
    });
    axios
      .post("/template/get_svg", { search: this.state.new_search })
      .then((response) => {
        return response.data;
      })
      .then((svg_image) => {
        let [, , height, width] = svg_image.image
          .match(/viewBox="(.*?)"/)[1]
          .split(" ");
        let style = svg_image.image.match(
          /<style type="text\/css">(.*?)<\/style>/s
        );
        if (style && style[1]) {
          style = style[1];
          svg_image.image = svg_image.image.replace(
            style,
            style + getFontCSS(svg_image.template.fonts, imageUrl)
          );
        }

        this.setState(
          {
            height,
            width,
            svg: svg_image.image,
            template: svg_image.template,
            loadingActive: false,
            loadingText: "",
            category: svg_image.template.category.slug,
          },
          () => {
            if (svg_image.template.template_type == "template") {
              this.getMessagesRelatedToTemplate();
            }
          }
        );
      })
      .catch((e) => {
        console.log(e);
        this.setState({ loadingActive: false });
        this.props.history.replace("/error-404");
      });
  }

  componentWillUnmount() {
    if (this.state.template) {
      let fields = JSON.parse(this.state.template.fields);
      let svg = document.getElementById("editTemplate").querySelector("svg");
      fields.forEach((field) => {
        if (field.type === "image" || field.type === "fb_image") {
          let image = svg.querySelector(`#${field.id}`);
          image.onclick = null;
        }
      });
    }
  }

  getMessagesRelatedToTemplate() {
    if (this.state.template) {
      let template_id = this.state.template.id;
      let category_name = this.state.category;
      let page = this.state.mrtt_page;
      let pageSize = this.state.mrtt_pageSize;
      axios
        .post("/messages-related-to-template", {
          template_id,
          category_name,
          page,
          pageSize,
        })
        .then((res) => {
          let result = res.data.data;
          // console.log(result);
          let new_data = [
            ...this.state.messages_related_to_templates,
            ...result,
          ];

          this.setState({
            messages_related_to_templates: new_data,
            mrtt_page: res.data.current_page_no,
            mrtt_total: res.data.total_page_no,
            fetchingMessages: false,
          });
        });
    }
  }

  checkAndAddScrollEvent() {
    // console.log('chekc scroll call')
    this.refs.related_messages.addEventListener("scroll", () => {
      if (
        Math.round(this.refs.related_messages.scrollTop) +
          this.refs.related_messages.clientHeight >=
        this.refs.related_messages.scrollHeight
      ) {
        if (
          this.state.mrtt_page <= this.state.mrtt_total &&
          !this.state.fetchingMessages
        ) {
          // console.log('inside if complae')
          this.setState(
            (prevState) => {
              return {
                mrtt_page: prevState.mrtt_page + 1,
                fetchingMessages: true,
              };
            },
            () => {
              // console.log('calling load more')
              this.getMessagesRelatedToTemplate();
            }
          );
        }
        // console.log('if')
        // console.log('scrolltop', this.refs.related_messages.scrollTop, "client height", this.refs.related_messages.clientHeight, "scroll height", this.refs.related_messages.scrollHeight)
      } else {
        // console.log('else')
        // console.log('scrolltop', this.refs.related_messages.scrollTop, "client height", this.refs.related_messages.clientHeight, "scroll height", this.refs.related_messages.scrollHeight)
      }
      this.setState({ scrollMounted: true });
    });
  }

  cropDone(image) {
    this.state.selected_item.setAttribute("xlink:href", image);
    this.setState({
      modal: false,
    });
  }

  textFocus(e) {
    if (e.target.contentEditable) {
      this.setState({
        textFocus: true,
        selectedText: e.target,
      });
    }
  }

  textBlur(e) {
    if (
      (e.target.closest("#editTemplate") && !e.target.contentEditable) ||
      e.target.id === "editTemplate"
    ) {
      this.setState({
        textFocus: false,
      });
    }
  }

  changeMessage(message) {
    // console.log(message.message);
    let message_fields =
      typeof message.fields === "object"
        ? message.fields
        : JSON.parse(message.fields);
    let messageFieldValue;
    let svg = document.getElementById("editTemplate").querySelector("svg");
    JSON.parse(this.state.template.fields).forEach((field) => {
      if (field.type === "text") {
        if (message.message != "") {
          console.log(message.message);
          replaceTextWithDiv(svg, field, message.message);
        }
      } else if (
        (field.type === "author" || field.type == "placeholder") &&
        (field.type != "image" || field.type != "fb_image")
      ) {
        messageFieldValue = message_fields.filter((message_field) => {
          return (
            message_field.type === field.type ||
            message_field.name === field.type
          );
        })[0];
        // console.log(messageFieldValue);
        replaceTextWithDiv(
          svg,
          field,
          messageFieldValue ? messageFieldValue.value : " "
        );
      }
    });
  }

  render() {
    const isActive = this.state.loadingActive;
    const loadingText = this.state.loadingText;
    return (
      <>
        <section className="bg-artboard container-fluid p-0">
          <div className="row">
            <div className="col-sm-7 col-md-9">
              {this.state.successOrReject && (
                <div>
                  <Alert
                    color="success"
                    toggle={this.onAlertDismiss}
                    fade={false}
                    className="mb-0 text-center"
                  >
                    Thank you for using Subhakamana.com. You can resend it to
                    your friends and relatives through other social media as
                    well. To go to the{" "}
                    <strong>list of subhakamana template</strong> please
                    <button
                      className="btn btn-link btn-sm"
                      onClick={this.goToSearchWithCategory}
                    >
                      click here
                    </button>
                    .For feedback<a href="/user/feedback"> click here.</a>
                  </Alert>
                </div>
              )}
              <LoadingOverlay active={isActive} spinner text={loadingText}>
                <TransformWrapper scale={1}>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                      <PanelControlContainer className="position-absolute">
                        <button
                          onClick={zoomIn}
                          className="btn bg-white text-secondary shadow mr-2"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                        <button
                          onClick={zoomOut}
                          className="btn bg-white text-secondary shadow mr-2"
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                        <button
                          onClick={resetTransform}
                          className="btn bg-white text-secondary shadow mr-2"
                        >
                          <FrameIcon style={{ height: "20px" }} />
                        </button>
                        {!this.state.convertedImage && (
                          <button
                            onClick={this.handleConvertSvg}
                            className="btn btn-secondary shadow"
                            disabled={this.state.loading}
                            type="button"
                          >
                            <i className="fa fa-check"></i>
                          </button>
                        )}
                        {this.state.textFocus && (
                          <div
                            style={{ width: 300 }}
                            className="bg-white mx-auto px-4 py-2 mt-3"
                          >
                            <InputRange
                              maxValue={100}
                              minValue={16}
                              value={this.state.fontSize_slider}
                              onChange={(value) =>
                                this.setState({ fontSize_slider: value })
                              }
                            />
                          </div>
                        )}
                      </PanelControlContainer>
                      <TransformComponent>
                        <div
                          className="artboard"
                          onClick={this.textBlur}
                          onFocus={this.textFocus}
                        >
                          <SvgWrapper>
                            <div
                              className=""
                              id="editTemplate"
                              dangerouslySetInnerHTML={{
                                __html: this.state.svg,
                              }}
                            ></div>
                          </SvgWrapper>
                        </div>
                      </TransformComponent>
                      <div className="done-block position-fixed fixed-bottom w-100 text-center flex">
                        <FacebookProvider appId="3155480471408762">
                          {this.state.convertedImage && (
                            <Sharebuttonwrapper>
                              <span
                                className="label"
                                style={{
                                  width: "100%",
                                  fontSize: "16px",
                                  marginTop: "10px",
                                }}
                              >
                                Share With :-
                              </span>
                              {this.state.isWeb ? (
                                <>
                                  <WhatsappShareButton
                                    url={this.state.convertedImage}
                                    onShareWindowClose={
                                      this.handleShareByReactShare
                                    }
                                  >
                                    <i className="fab fa-whatsapp-square"></i>
                                  </WhatsappShareButton>
                                  <LinkedinShareButton
                                    url={this.state.convertedImage}
                                    onShareWindowClose={
                                      this.handleShareByReactShare
                                    }
                                  >
                                    <i className="fab fa-linkedin-square"></i>
                                  </LinkedinShareButton>
                                  <button type="button" onClick={this.fbShare}>
                                    <i className="fab fa-facebook-square"></i>
                                  </button>
                                  <button
                                    type="button"
                                    onClick={this.sharePrivatelyFriends}
                                  >
                                    <i className="fab fa-facebook-messenger"></i>
                                  </button>
                                </>
                              ) : (
                                <>
                                  {!isMobileSharable() && (
                                    <>
                                      <button
                                        type="button"
                                        onClick={this.sharePrivatelyFriends}
                                      >
                                        <i className="fab fa-facebook-messenger"></i>
                                      </button>
                                      <button
                                        type="button"
                                        onClick={this.fbShare}
                                      >
                                        <i className="fab fa-facebook-square"></i>
                                      </button>
                                      {!this.state.isWeb && (
                                        <a
                                          href={`viber://forward?text=subhakamana https://www.subhakamana.com/start/${this.props.match.params.slug}/${this.state.convertedImage}`}
                                        >
                                          <i className="fab fa-viber"></i>
                                        </a>
                                      )}
                                    </>
                                  )}
                                  {isMobileSharable() && (
                                    <button
                                      type="button"
                                      className="btn bg-primary border"
                                      onClick={this.mobileShare}
                                      style={{ height: "40px" }}
                                    >
                                      <i className="fas fa-share"></i>
                                    </button>
                                  )}
                                </>
                              )}
                            </Sharebuttonwrapper>
                          )}
                        </FacebookProvider>
                      </div>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              </LoadingOverlay>
              {this.state.modal && (
                <CustomeModal
                  onDone={this.Done}
                  onClose={() => this.setState({ modal: false })}
                  title="Adjust your Image"
                >
                  <CropImage
                    image={this.state.cropImage}
                    height={this.state.canvas_height}
                    width={this.state.canvas_width}
                    onDone={this.cropDone}
                  />
                </CustomeModal>
              )}
              {this.state.facebookLoginModal && (
                <CustomeModal
                  title="For better view of template Please do Login"
                  onClose={this.closeLoginModal}
                >
                  <FacebookLogin onClick={this.fblogin}>
                    <i className="fab fa-facebook-square"></i> Continue with
                    Facebook
                  </FacebookLogin>
                </CustomeModal>
              )}
              {this.state.errModal && (
                <CustomeModal title="Oops!" onClose={this.closeErrModal}>
                  Something went wrong! Please try again later.
                </CustomeModal>
              )}
            </div>
            {this.state.messages_related_to_templates.length > 1 && (
              <div
                className="col-sm-5 col-md-3"
                style={{
                  maxHeight: "calc(100vh - 80px)",
                  padding: "10px",
                  right: 0,
                  overflowY: "scroll",
                }}
                ref="related_messages"
                id="mes_re_to_temo"
              >
                {this.state.messages_related_to_templates.map(
                  (message, index) => {
                    return (
                      <div
                        key={index}
                        className="border mt-2 mb-1 p-2 rounded shadow bg-white pv-4"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.changeMessage(message);
                        }}
                      >
                        {message.message.split("\\n").join(" ")}
                      </div>
                    );
                  }
                )}
                {this.state.fetchingMessages && (
                  <div
                    style={{ backgroundColor: "white", textAlign: "center" }}
                  >
                    Loading messages...
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
}

export default View;

const SvgWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;

  & > div {
    display: flex;
    height: 100%;
    width: 100%;
    svg {
      max-height: 100%;
      margin: auto;
      width: 800px;
      max-width: 100%;
    }
  }
  [contenteditable="true"] {
    border: 2px dashed rgba(0, 0, 0, 0.5);
  }
`;

const PanelControlContainer = styled.div`
  top: 20px;
  width: 100%;
  text-align: center;
  z-index: 2;
`;

const Sharebuttonwrapper = styled.div`
	font-size:40px;
	display:flex;
	flex-wrap:wrap;
	background-color:#fff;
	justify-content:center;
	align-items:center;
	.label{
		font-size:20px;
	}	

	button,[role="button"]{
		background:none;
		border:0
		padding:0 20px;
	}
	.fab{
		color:#fff;	
		border-radius:3px;
	}
	.fa-viber{
		color:#665CAC;
	}
	.fa-facebook-square{
		color:#3C5A99;
	}
	.fa-facebook-messenger{
		color:#0078FF;
	}
	.fa-whatsapp-square{
		color:#4AC959;
	}
	.fa-linkedin-square{
		color:#0077B5;
	}
`;

let FacebookLogin = styled.button`
  background-color: #3c5a99;
  color: #fff;
  border: 0;
  font-size: 25px;
  padding: 5px 20px;
  border-radius: 3px;
  font-family: helvetica, arial, sans-serif;
`;
